import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { auth } from '../firebase/firebase.js'
import { useNavigate } from "react-router-dom"
import { startAddProfile } from "../actions/profModels"
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';
import Spinner from "react-svg-spinner";


const Register = (props) => {

    const navigate = useNavigate()
    const [error1, setError] = useState({})
    const [passwordError, setPasswordError] = useState(false)
    const [passwordError2, setPasswordError2] = useState(false)
    const [linkSent, setLinkSent] = useState(false)
    const [sendingLink, setSendingLink] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const nameRef = useRef();
    const emailRef = useRef();
    const password1Ref = useRef(); 
    const password2Ref = useRef();

    // if (!window.setImmediate) {
    //     window.setImmediate = function(callback) {
    //       setTimeout(callback, 0);
    // };}

    function isTimeMoreThanTenSecondsOld(timeString) {
        // Parse the provided time string to a Date object
        const providedTime = new Date(timeString);
        
        // Get the current time
        const currentTime = new Date();
        
        // Calculate the difference in seconds
        const differenceInSeconds = (currentTime - providedTime) / 1000;
        
        // Check if the difference is more than 10 seconds
        return differenceInSeconds > 10;
    }

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                if (!currentUser.emailVerified) {
                    setLinkSent(true)
                } else {
                    navigate('/')
                }
            } else {
                // console.log('not logged in')
            }
        })
    }, [])

    const register = async (e) => {
        e.preventDefault()
        setSendingLink(true)

        if (password1.length < 8) {

        }
        if (name == '') {  
            setNameError(true)
            setSendingLink(false)
            return;
        } else {
            setNameError(false)
        }

        if (email == '') { 
            setEmailError(true)
            setSendingLink(false)
            return;
        } else {
            setEmailError(false)
        }
        
        setError('')
        
        

        if (password1.length < 8) {
            setPasswordError2(true)
            setSendingLink(false)
            return;
        } else {
            setPasswordError2(false)
        }

        if (password1 != password2) {
            setPasswordError(true)
            setSendingLink(false)
            return;
        } else {
            setPasswordError(false)
        }


        try {
            const user = await createUserWithEmailAndPassword(auth, email, password1)
            await sendEmailVerification(user.user)
            let theTimeStamp = moment().utc().toString()
            let theOccurrence = uuidv4()
            const profModel = {
                name: name,
                email: email,
                contactEmail: email,
                updates: {
                    createdClaims: [],
                    uploadedFigures: [],
                    authoredTheories: [],
                    identifiedPapers: [],
                    comments: [],
                    joined: [
                        {
                            occurrence: theOccurrence,
                            timeStamp: theTimeStamp
                        }
                    ],
                    follows: []
                }
            }
            await props.startAddProfile(profModel, user.user.uid)
            

        } catch (error) {
            const errorMessage = error.message.replace('Firebase: ', '')
            let error1 = {
                theMessage: errorMessage
            }
            console.log('hmm')
            setError(error1)
            setSendingLink(false)
        }
    }

    return (
        <div>
            <div className="theHeader">
                <div className="head">
                    <div id="imgWrap">
                        <img id="LogoImg" src="/Images/Book2.jpg"/>
                    </div>
                    <div className='websiteTitleLogin'>
                        <div>
                            <div id="div3">Outwit</div>
                            <div id="div4">Channel the power of collective minds</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='spacerLogin'></div>
            <div className="login2">
                <h2 className="Login">Register for Outwit</h2>
                <div>
                    <div hidden={linkSent}>
                        <div className="RegisterSubtitleDiv">
                            <div>
                                <div className='RegisterSubtitle'>Name</div>
                                <input className="fullName2" type="text" ref={nameRef} onChange={(event) => {setName(event.target.value)}}
                                placeholder='Enter your first and last name'/>
                            </div>
                        </div>
                        <div className="RegisterSubtitleDiv">
                            <div>
                                <div className='RegisterSubtitle'>Email</div>
                                <input className="email2" type="text" ref={emailRef} onChange={(event) => {setEmail(event.target.value)}}
                                placeholder='Enter your Email'/>
                            </div>
                        </div>
                        <div className="RegisterSubtitleDiv">
                            <div>
                                <div className='RegisterSubtitle'>Password</div>
                                <input className="fullName2" type="password" ref={password1Ref} onChange={(event) => {setPassword1(event.target.value)}}
                                placeholder='Enter your Password'/>
                            </div>
                        </div>
                        <div className="RegisterSubtitleDiv">
                            <div>
                                <div className='RegisterSubtitle'>Verify Password</div>
                                <input className="fullName2" type="password" ref={password2Ref} onChange={(event) => {setPassword2(event.target.value)}}
                                placeholder='Verify your Password'/>
                            </div>
                        </div>
                        <div className="passwordIssues">
                            {nameError && 'Your name is required'}
                        </div>
                        <div className="passwordIssues">
                            {emailError && 'Your email is required'}
                        </div>
                        <div className="passwordIssues">
                            {passwordError && 'passwords are not the same'}
                        </div>
                        <div className="passwordIssues">
                            {passwordError2 && 'passwords must be at least 8 spaces long and contain at least one number'}
                        </div>
                        <div className="passwordIssues">{error1 && error1.theMessage}</div>
                    </div>
                    <div className={!linkSent ? 'RegisterSignUpButtonDivRegister' : 'hidden'}>
                        <button className="RegisterSignUp2" onClick={register}>
                            <b hidden={sendingLink}>Sign Up</b>
                            <Spinner hidden={!sendingLink || nameError || emailError || passwordError || passwordError2} />
                        </button>
                    </div>
                    <div className={linkSent ? 'RegisterLinkSent' : 'hidden'}>
                        Please check your email for a verification link
                    </div>
                    <div className="terms">
                        By clicking Sign Up, you agree to our Terms, Data Policy, and Cookies Policy
                    </div>
                </div>
                <div className="already">Already have an account? Login 
                    <a className='navigateToLogin' onClick={() => {navigate('/Login')}}>here</a>
                </div>
            </div>
        </div>
        
    )
}

const mapDispatchToProps = (dispatch) => ({
    startAddProfile: (profile, id) => dispatch(startAddProfile(profile, id))
})

const ConnectedRegister = connect(undefined, mapDispatchToProps)(Register);

export default ConnectedRegister;