import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import FigureClaimSupport from './figureClaimStatelessSupport';
import FigureClaimContra from './figureClaimStatelessContra';
import { useNavigate } from "react-router-dom"
import { history } from '../routers/AppRouter'
import { startAddClaimFollow, startRemoveClaimFollow, getClaimInfo, returnClaimInfoHomeFeed, setClaim} from '../actions/claimModels';
import Tippy from '@tippyjs/react'
import LoadingScreen from './loadingScreen';
import moment from 'moment';
import SVGConnection from './SVGs/SVGConnection';
import SVGaddFigure from './SVGs/SVGaddFigure';
import SVGDelete from './SVGs/SVGDelete';
import SVGOptionsDots from './SVGs/SVGoptionsDots';
import DeleteModal from './deleteModalClaim';
import { updateLastViewedClaim } from '../actions/claimModels';
import { v4 as uuidv4 } from 'uuid';


const Claim = (props) => {
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [optionsOpen2, setOptionsOpen2] = useState(false)

    const navigate = useNavigate()
    const connectClaim = () => {
        navigate('/ConnectClaim/' + claimID)
    }

    

    function getUniqueEngagement (objects) {
        const seenIds = new Set();
        return objects.filter(obj => {
            if (seenIds.has(obj.id)) {
            return false;
            } else {
            seenIds.add(obj.id);
            return true;
            }
        });
    }

    

    function removeEngagement (array1, array2) {
        return array1.filter(obj1 => !array2.some(obj2 => obj1.occurrence === obj2.occurrence));
    }
    
    const [followed, setFollowed] = useState(false)
    const [supportClicked, setSupportClicked] = useState(true)
    const [uniqueEngagement, setUniqueEngagement] = useState([])
    const [totalEngagement, setTotalEngagement] = useState([])
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [timeStampPageVisit, setTimeStampPageVisit] = useState()

    const [title, setTitle] = useState('')
    const [ownerTags, setOwnerTags] = useState([])
    const [localDate, setLocalDate] = useState()
    const [paperDoi, setPaperDoi] = useState('')
    const [paperID, setPaperID] = useState('')
    const [updatedLastViewedAlready, setUpdatedLastViewedAlready] = useState(false)
    const [doesNotSupportTally, setDoesNotSupportTally] = useState(0)
    const [somewhatSupportsTally, setSomewhatSupportsTally] = useState(0)
    const [reallySupportsTally, setReallySupportsTally] = useState(0)
    const [doesNotContradictTally, setDoesNotContradictTally] = useState(0)
    const [somewhatContradictsTally, setSomewhatContradictsTally] = useState(0)
    const [reallyContradictsTally, setReallyContradictsTally] = useState(0)
    const [extractedClaim, setExtractedClaim] = useState(false)
    const [associatedTheories, setAssociatedTheories] = useState([])
    const [notDeleted, setNotDeleted] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [supportFigsToShow, setSupportFigsToShow] = useState([])
    const [contraFigsToShow, setContraFigsToShow] = useState([])
    const [claimID, setClaimID] = useState('')
    const [exactText, setExactText] = useState('')
    const [claimOwner, setClaimOwner] = useState('')
    const [follows, setFollows] = useState([])
    const [claimConfidence, setClaimConfidence] = useState('')
    useEffect( async () => {
        let URLpath = window.location.pathname
        let split = URLpath.split('/')
        let id;
        if (split.length == 3) {
            id = split[2]
        }
        try {
            setClaimID(id)
            //Make call to Firebase with returnClaimInfoUpdates function to fetch info off of database.
            let claimInfo = await props.returnClaimInfoHomeFeed(id)
            //If info is able to be fetched, update all relevant state values.
            if (claimInfo) {
                const theDate = new Date(claimInfo.timeStamp)
                const newMoment = moment(theDate)
                newMoment.local()
                const theLocalDate = newMoment.format('LLL')

                //Here we record the time that the info of this claim was pulled off of Firebase. This will
                //be used as the time the user last viewed this claim. We call the appropriate function:
                let timeStamp = moment().utc().toString()
                setTimeStampPageVisit(timeStamp)

                for(var i = 0; i < claimInfo.follows.length; i++) {
                    if (claimInfo.follows[i].id === props.user) {
                        setFollowed(true)
                        break;
                    }
                }

                setTotalEngagement(claimInfo.engagement)
                setAssociatedTheories(claimInfo.associatedTheories)
                setSupportFigsToShow(claimInfo.supportFigs)
                setContraFigsToShow(claimInfo.contraFigs)
                setLocalDate(theLocalDate)
                setExtractedClaim(claimInfo.extractedClaim)
                setPaperID(claimInfo.paperID)
                setPaperDoi(claimInfo.paperDoi)
                setOwnerTags(claimInfo.ownerTags)
                setExactText(claimInfo.exactText)
                setClaimOwner(claimInfo.owner)
                setFollows(claimInfo.follows)
                setLoaded(true)
                
                setTitle(claimInfo.title)
                setDoesNotSupportTally(gatherDoesNotSupportTally(claimInfo.supportFigs))
                setSomewhatSupportsTally(gatherSomewhatSupportsTally(claimInfo.supportFigs))
                setReallySupportsTally(gatherReallySupportsTally(claimInfo.supportFigs))
                setDoesNotContradictTally(gatherDoesNotContradictTally(claimInfo.contraFigs))
                setSomewhatContradictsTally(gatherSomewhatContradictsTally(claimInfo.contraFigs))
                setReallyContradictsTally(gatherReallyContradictsTally(claimInfo.contraFigs))

            } else {
                //If the figure is not available on Firebase, it is probably because it was deleted.
                //If it was deleted, nothing should be shown.

                //Also set to false if there are no updates to be shown for this claim so that it does
                //not show up.
                setNotDeleted(false)
            }
        } catch (err) {
            // console.log(err)
        }
    }, [])


    useEffect(() => {
        setClaimConfidence(checkingIt2())
    }, [doesNotSupportTally, somewhatSupportsTally, reallySupportsTally, doesNotContradictTally, somewhatContradictsTally, reallyContradictsTally])


    let optionsRefDiv = useRef();
    let optionsRefIcon = useRef();
    let optionsRefDiv2 = useRef();
    let optionsRefIcon2 = useRef();


    useEffect(() => {
        let theUniqueEngagement = getUniqueEngagement(totalEngagement)
        setUniqueEngagement(theUniqueEngagement)
    }, [totalEngagement])

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv.current.contains(event.target) || optionsRefIcon.current.contains(event.target))) {
                setOptionsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        let handler = (event) => {
            if(!(optionsRefDiv2.current.contains(event.target) || optionsRefIcon2.current.contains(event.target))) {
                setOptionsOpen2(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(async () => {
        if (props.loggedIn) {
            for(var i = 0; i < follows.length; i++) {
                if (follows[i].id === props.user) {
                    setFollowed(true)
                    break;
                }
            }
            let timeStamp = moment().utc().toString()
            // await props.updateLastViewedClaim(props.user, props.id, timeStamp)
            setTimeStampPageVisit(timeStamp)
        }
    }, [])

    //The following three functions get the tally for each type of vote in order to calculate the initial confidence value.
    const gatherDoesNotSupportTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.doesNotSupport.length;
        })
        return currentTally;
    }

    const gatherSomewhatSupportsTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.somewhatSupports.length;
        })

        return currentTally;
    }

    const gatherReallySupportsTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.reallySupports.length;
        })

        return currentTally;
    }

    const gatherDoesNotContradictTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.doesNotSupport.length;
        })
        return currentTally;
    }

    const gatherSomewhatContradictsTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.somewhatSupports.length;
        })
        return currentTally;
    }

    const gatherReallyContradictsTally = (array) => {
        let currentTally = 0;
        array.forEach((fig) => {
            currentTally += fig.reallySupports.length;
        })
        return currentTally;
    }

    
    
    

    //This function takes into account all of the vote tallies and calculates the confidence of the claim.
    const checkingIt2 = () => {
        let supportCalculation = 100 * (reallySupportsTally*3 + somewhatSupportsTally)

        let contradictCalculation = (reallyContradictsTally*3 + somewhatContradictsTally - doesNotContradictTally + doesNotSupportTally 
            + reallySupportsTally*3 + somewhatSupportsTally)
        
        if (supportCalculation < 0 || isNaN(supportCalculation) ) {
            supportCalculation = 0;
        }

        if (contradictCalculation < 0 || isNaN(contradictCalculation) ) {
            contradictCalculation = 1;
        }

        let calculation = supportCalculation/contradictCalculation;

        if (calculation < 0) {
            calculation = 0;
        }

        if ((somewhatContradictsTally + reallyContradictsTally) == 0 && (reallySupportsTally + somewhatSupportsTally == 0)) {
            return "?";
        }


        if (isNaN(calculation)) {
            
            return "?";
        }

        if (Math.trunc(calculation) > 100) {
            return '100%'
        }

        if (Math.trunc(calculation) < 0) {
            return '0%'
        }

        return Math.trunc(calculation) + "%";
    }


    //This is just a function that allows the user to follow the claim or unfollow the claim.
    const followClaim = () => {
        setFollowed(!followed)
        if (!followed) {
            let timeStamp = moment().utc().toString()
            let occurrence = uuidv4();
            let timeStamps = {
                followed: timeStamp,
                visited: timeStampPageVisit
            }
            props.startAddClaimFollow(props.user, claimID, occurrence, timeStamps).then(() => {
                
            }).catch(() => {
                // console.log('claim was not followed')
            })
        } else if (followed) {
            props.startRemoveClaimFollow(props.user, claimID).then(() => {
                
            }).catch(() => {
                // console.log('claim was not followed')
            })
        } else {
            // console.log('something wrong with claim-follow logic')
        }
        
    }


    const reportClaim = () => {
        navigate('/Report/Claim/' + claimID)
    }

    const addClaim = () => {
        navigate('/AddClaim')
    }

    function removeSubstring(originalString) {
        let split = originalString.split('/')
        let theURL = '/' + split[3] + '/' + split[4]
        return theURL;
    }

    function handleClick(event) {
        event.preventDefault();
        // console.log(event.target)
        let URL = removeSubstring(event.target.href, event.target.baseURI)
        navigate(URL)
    }

    return (
        <div className="claimShow">
            <div hidden={props.loggedIn} className="loginNotice" ><a className="anchor" href="/Login">
                Login or register an account</a> to create or interact with claims.
            </div>
            {deleteModalState && <DeleteModal id={claimID} closeModal={setDeleteModalState} element='claim' />}
            <h1 className="notFound" hidden={notDeleted}>Error: Claim does not exist or was deleted</h1>
            <div className='majorContainer1' hidden={!notDeleted}>
                <div className='claimTitleandStats'>
                    <div className='confidenceAndStatsClaim'>
                    
                        <div className="ConfidencePercentageDiv">
                            <h1>Confidence:</h1>
                            <h1>{claimConfidence}</h1>
                        </div>
                        <div className="ConfidencePercentageDiv">
                            <h1>Engagement:</h1>
                            <h1>{uniqueEngagement.length}</h1>
                        </div>
                    </div>
                    <h1 className="h1ClaimTitle">{title}</h1>
                    <div className="h1ClaimTitleSmallDiv">
                        <h1 className="h1ClaimTitle2">{title}</h1>
                        <div className='optionsClaim2' onClick={() => setOptionsOpen2(!optionsOpen2)} ref={optionsRefIcon2}>
                            <SVGOptionsDots/>
                        </div>
                        <div className="dropDownClaimWrapper2" ref={optionsRefDiv2} hidden={!optionsOpen2} >
                            <div className='dropDownClaim3'>
                                <div className='optionsAnchorDiv' hidden={props.user != claimOwner} onClick={() => {connectClaim()}}>
                                    <SVGConnection/>
                                    <a><b>Connect figures</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={() => {addClaim()}}>
                                    <SVGaddFigure/>
                                    <a><b>Create a new Claim</b></a>
                                </div>
                                <div className={props.user == claimOwner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => setDeleteModalState(true)}  
                                href={props.image}>
                                    <SVGDelete/>
                                    <a><b>Delete this Claim</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={reportClaim}>
                                    <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                        0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                    </svg>
                                    <a><b>Report this Claim</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={props.loggedIn ? "followSVGClaim" : "invisibleClaim"}>
                        <Tippy content={followed ? "Unfollow Claim" : "Follow Claim"} >
                            <svg className={followed ? "followSVGSVGClaimClicked" : "followSVGSVGClaim"} onClick={followClaim} version="1.1" 
                            viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                    fill={followed ? "#fff" : "#002c2c"}/>
                                </svg>
                            </svg>
                        </Tippy>
                        <div className='optionsClaim' onClick={() => setOptionsOpen(!optionsOpen)} ref={optionsRefIcon}>
                            <SVGOptionsDots/>
                        </div>
                        <div className="dropDownClaimWrapper" ref={optionsRefDiv} hidden={!optionsOpen} >
                            <div className='dropDownClaim2'>
                                <div className={props.user == claimOwner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => {connectClaim()}}>
                                    <SVGConnection/>
                                    <a><b>Connect figures</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={() => {addClaim()}}>
                                    <SVGaddFigure/>
                                    <a><b>Create a new Claim</b></a>
                                </div>
                                <div className={props.user == claimOwner ? 'optionsAnchorDiv' : 'hidden'} onClick={() => setDeleteModalState(true)}  
                                href={props.image}>
                                    <SVGDelete/>
                                    <a><b>Delete this Claim</b></a>
                                </div>
                                <div className='optionsAnchorDiv' onClick={reportClaim}>
                                    <svg className='marginSVG' width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 
                                        0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" fill="#002c2c"/>
                                    </svg>
                                    <a><b>Report this Claim</b></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="smallConfidenceAndFollow">
                        <div className='confidenceAndStatsClaim2'>
                            <div className="ConfidencePercentageDiv">
                                <h1>Confidence:</h1>
                                <h1>{claimConfidence}</h1>
                            </div>
                            <div className="ConfidencePercentageDiv">
                                <h1>Engagement:</h1>
                                <h1>{uniqueEngagement.length}</h1>
                            </div>
                        </div>
                        <div className='toggleButtonsDiv'>
                            <button className={supportClicked ? 'supportFigButtonsToggleClicked' : 'supportFigButtonsToggle'} 
                            onClick={() => setSupportClicked(true)}><b>Support</b></button>
                            <button className={supportClicked ? 'contraFigButtonsToggle' : 'contraFigButtonsToggleClicked'} 
                            onClick={() => setSupportClicked(false)}><b>Contradict</b></button>
                        </div>
                        <div className={props.loggedIn ? "followSVGClaimSmall" : "invisibleClaimSmall"}>
                            <Tippy content="Follow Claim" >
                                <svg className={followed ? "followSVGSVGClaimClicked" : "followSVGSVGClaim"} onClick={followClaim} version="1.1" 
                                    viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                    <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                        fill={followed ? "#fff" : "#002c2c"}/>
                                    </svg>
                                </svg>
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className='exactTextDivClaim' hidden={!extractedClaim}>
                    <div className='extractedClaim'><b>This claim was extracted from a <a className="paperAnchor" href={'/Paper/' + paperID}
                    onClick={handleClick}>
                        paper</a></b>
                    </div>
                    <div className='extractedClaim'><b>Exact text from the paper: </b><span className='exactTextQuote'>"{exactText}"</span></div>
                </div>
                <div className='divAbove2Theory'>
                    <div className='addedBy'><b>Created by:</b></div>
                    
                    {
                        ownerTags.map((tag, index) => {
                            return (<a key={tag.displayName + index} className="anchorNameClaim" href={'/Profile/' + tag.id }
                            onClick={handleClick}>{tag.displayName}</a>)
                        })
                    }
                </div>
                <div className='divAbove3Theory'><b>- {localDate}</b></div>
                <div className='divAbove2ClaimTheory'>
                    <div className='addedBy'>
                        <b>
                            {associatedTheories.length > 0 ? 'Associated Theories:' : 'No theories are associated with this claim yet.'}
                        </b>
                    </div>
                    {
                        associatedTheories.map((theory) => {
                            return (<div key={theory.title + theory.id} className='divAbove3TheoryAssociated'>
                                    <b className='blackFont'>- </b>
                                    <a className='linkVisited2' href={'/Theory/' + theory.id } onClick={handleClick}>
                                        {theory.title}
                                    </a>
                                </div>)
                        })
                    }
                </div>
                <div className="columnFlex">
                    <div className={supportClicked ? "supColumn" : "supColumnNotClicked"}>
                        <div className="columnTop">
                            <h1 className="supportColumnH1">Support</h1>
                            <svg onClick={connectClaim} hidden={!props.loggedIn}  id="input7" version="1.1" 
                            viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                    fill="rgb(98, 189, 184)"/>
                                </svg>
                            </svg>
                        </div>
                        {
                            supportFigsToShow.map((fig, supportFigIndex) => {
                                return (
                                    <FigureClaimSupport key={fig.id} loggedIn={props.loggedIn} 
                                    imgsrc={fig.imgsrc} figid={fig.id} user={props.user} claimID = {claimID} 
                                 reallySupports={fig.reallySupports} somewhatSupports={fig.somewhatSupports} 
                                 doesNotSupport={fig.doesNotSupport} title={fig.title} 
                                 description={fig.description} ownerTags={fig.ownerTags} connector={fig.owner} 
                                 criticisms={fig.criticisms} figIndex={supportFigIndex} figType="support" tags={props.tags}
                                 userName={props.userName} supportFigsToShow={supportFigsToShow}
                                 setDoesNotSupportTally={setDoesNotSupportTally} setSomewhatSupportsTally={setSomewhatSupportsTally} 
                                 setReallySupportsTally={setReallySupportsTally} explanation={fig.explanation}
                                 doesNotSupportTally={doesNotSupportTally} somewhatSupportsTally={somewhatSupportsTally} 
                                 reallySupportsTally={reallySupportsTally} totalEngagement={totalEngagement} 
                                 setTotalEngagement={setTotalEngagement} removeEngagement={removeEngagement}
                                 setSupportFigsToShow={setSupportFigsToShow}/>
                                )   
                            })
                        }
                        <div className={(supportFigsToShow.length == 0) ? 'noFiguresClaim' : 'hidden'}>
                        No supporting figures have been connected to this claim.</div>
                    </div>
                    <div className={supportClicked ? "contraColumnNotClicked" : "contraColumn"}>
                        <div className="columnTop">
                            <h1 className="contraColumnH1">Contradict</h1>
                            <svg onClick={connectClaim} hidden={!props.loggedIn}  id="input7Contra" version="1.1" 
                            viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                                <svg width="288" height="288" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path className="color000 svgShape" d="m24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" 
                                    fill="rgb(228, 92, 92)"/>
                                </svg>
                            </svg>
                        </div>
                        {
                            contraFigsToShow.map((fig, contraFigIndex) => {
                                return (
                                    <FigureClaimContra key={fig.imgsrc} loggedIn={props.loggedIn} 
                                    imgsrc={fig.imgsrc} figid={fig.id} user={props.user} claimID = {claimID} 
                                 reallySupports={fig.reallySupports} somewhatSupports={fig.somewhatSupports} 
                                 doesNotSupport={fig.doesNotSupport} title={fig.title} explanation={fig.explanation}
                                 description={fig.description} ownerTags={fig.ownerTags} connector={fig.owner} 
                                 criticisms={fig.criticisms} figIndex={contraFigIndex} figType="contradict" tags={props.tags}
                                 userName={props.userName} contraFigsToShow={contraFigsToShow}
                                 setDoesNotContradictTally={setDoesNotContradictTally} setSomewhatContradictsTally={setSomewhatContradictsTally} 
                                 setReallyContradictsTally={setReallyContradictsTally} doesNotContradictTally={doesNotContradictTally} 
                                 somewhatContradictsTally={somewhatContradictsTally} reallyContradictsTally={reallyContradictsTally}
                                 totalEngagement={totalEngagement} setContraFigsToShow={setContraFigsToShow}
                                 setTotalEngagement={setTotalEngagement} removeEngagement={removeEngagement}/>
                                )   
                            })
                        }
                        <div className={(contraFigsToShow.length == 0) ? 'noFiguresClaim' : 'hidden'}>No contradicting figures have been 
                        connected to this claim.</div>
                    </div>
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state, props) => {
    if (state.profModels.length == 1) {
        return {
            user: state.profModels[0].id,
            userName: state.profModels[0].name,
            tags: state.profModels[0].tags,
            loggedIn: true
        };
    } else {
        return {
            user: '',
            userName: '',
            tags: [],
            loggedIn: false
        }
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    startSetFigure: (id) => dispatch(startSetFigure(id)),
    getClaimInfo: (id) => dispatch(getClaimInfo(id)),
    startAddCriticism: (id, userName, criticism) => dispatch(startAddCriticism(id, userName, criticism)),
    startSetProfile: (email) => dispatch(startSetProfile(email)),
    startDeleteCriticism: (critText, id, imgsrc) => dispatch(startDeleteCriticism(critText, id, imgsrc)),
    startRejectFigure: (downVote) => dispatch(startRejectFigure(downVote)),
    reverseRejectFigure: (downVote) => dispatch(reverseRejectFigure(downVote)),
    startAcceptFigure: (upVote) => dispatch(startAcceptFigure(upVote)),
    reverseAcceptFigure: (upVote) => dispatch(reverseAcceptFigure(upVote)),
    startAddClaimFollow: (user, id, occurrence, timeStamp) => dispatch(startAddClaimFollow(user, id, occurrence, timeStamp)),
    startRemoveClaimFollow: (user, id) => dispatch(startRemoveClaimFollow(user, id)),
    updateLastViewedClaim: (user, id, timeStamp) => dispatch(updateLastViewedClaim(user, id, timeStamp)),
    returnClaimInfoHomeFeed: (id) => dispatch(returnClaimInfoHomeFeed(id))
})

const ConnectedClaim = connect(mapStateToProps, mapDispatchToProps)(Claim);

export default ConnectedClaim;